import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as turf from '@turf/turf';
import { QuestionModalComponent } from '../components/questionModal/questionModal.component';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameService } from './game.service';
import { Router } from '@angular/router';
import Noty from 'noty';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';
import confetti from 'canvas-confetti';
import { StorageService } from './storage.service';
import { Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

declare var Stripe;
@Injectable({
  providedIn: 'root',
})
export class GeoService {
  passwordInput: HTMLInputElement
  mapTouched = true;
  canLookup = true;
  stripe;
  modalActive = false;
  stillUpdating = false;
  lastMarkerNotyDropped = false;
  isPaymentActive = false;
  private triggerRequest = new Subject<{ wasCorrect: boolean, canLookup: any }>();

  constructor(private modalService: NgbModal, private gameService: GameService,  private router: Router,  private http: HttpClient, private localeService: LocaleService, private storageService: StorageService) {
    this.stripe = Stripe(environment.stripe);

    this.triggerRequest.pipe(
      switchMap(({ wasCorrect, canLookup }) => 
      this.http.post<any>(environment.baseUrl + 'games/updatePlayerOne', {gameId: this.gameService.activeGame._id, player: this.gameService.me, wasCorrect})
      .pipe(
        map(data => ({ data, canLookup }))
      ))
    ).subscribe(({ data, canLookup })  => {
      
      this.gameService.me = data.data;

      if(data.data.isFinished){
        this.endGame('end', true)
      } 

      setTimeout(()=>{
        this.checkUserHasPaid(data.data);
      }, 3000);
     
      this.gameService.activeGame.quests = [..._.filter(this.gameService.originalQuests, (q)=>{
        return this.gameService.me.passedQuests.indexOf(q._id) === -1 && !q.isEndPoi
      })]; 

      if(!_.find(this.gameService.activeGame.quests, (q)=>{
        return (!q.isEndPoi && !q.isEndPOITimeline)
      })){
        if(this.gameService.activeGame.isEndPOI){
          this.gameService.activeGame.quests = [..._.filter(this.gameService.originalQuests, (q)=>{
            return this.gameService.me.passedQuests.indexOf(q._id) === -1
          })];
          if(_.find(this.gameService.activeGame.quests, (fe)=>{return fe.isEndPoi === true}) && !this.lastMarkerNotyDropped){
            this.dropNoty('success', this.localeService.getTrans('Congrats! You unlocked the last marker!'));
            this.gameService.endQuest = _.find(this.gameService.activeGame.quests, (fe)=>{return fe.isEndPoi === true});
            this.gameService.endQuest.isVisible = true;
            this.lastMarkerNotyDropped = true;
          }
          if(this.gameService.activeGame.quests.length === 0){
            this.endGame('end');
          }
          /* if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
            this.endGame('end');
          } */
        } else{
          /* if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
            this.endGame('end');
          } */
          const endPOIQuestion = _.find(this.gameService.activeGame.quests, (qts)=>{return qts.isEndPOITimeline});
          if(this.gameService.activeGame.quests.length === 0 || (endPOIQuestion && this.gameService.activeGame.quests.length === 1)){
            this.endGame('end');
          }
        }
      }

      this.calculateRanking();
      setTimeout(()=>{
        this.stillUpdating = false;
        if(canLookup){
          this.canLookup = true;
        }
      },3000);
    });

  }

  passwordCheck(question, isIndoorMode, activePerks): Promise<boolean> {

    if (!question.password) return Promise.resolve(true);  // Resolve the Promise with true if no password is required

    return Swal.fire({
      title: "Password",
      text: "Type the password to continue",
      icon: 'question',
      confirmButtonColor: '#4E3B74',
      confirmButtonText: 'Check',
      heightAuto: false,
      didOpen: () => {
        const popup = Swal.getPopup()!;
        this.passwordInput = popup.querySelector('#password') as HTMLInputElement;
        
        // Add input event listener to track value changes
        this.passwordInput.oninput = (event: Event) => {
          const inputValue = (event.target as HTMLInputElement).value;
          console.log(inputValue);  // Track the current value of the password field
        };
    
        this.passwordInput.onkeyup = (event: KeyboardEvent) => {
          if (event.key === 'Enter') Swal.clickConfirm();
        };
      },
      preConfirm: () => {
        const password = this.passwordInput.value;
        if (password !== question.password) {
          Swal.showValidationMessage(`The password is incorrect`);
          return false;  // Return false if password does not match
        }
        this.doQuestion(question, isIndoorMode, activePerks);

        return true;  // Return true if password matches
      },
      html: `
        <input type="text" id="password" class="swal2-input" placeholder="Password">
      `,
    }).then((result) => {
      return result.isConfirmed && result.value;  // Resolve the Promise with true/false based on confirmation
    });
  }

  // Kezdet
  isQuestionNearby(questions, position, activePerks?) {

    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    this.canLookup = false;
    let qDetect = false;
    let outsideCircle = false;

   

   
    if(this.gameService.endQuest.isVisible){
        const distance = turf.distance(
            turf.point([position.lng, position.lat]),
            turf.point([this.gameService.endQuest.lng, this.gameService.endQuest.lat]),
            { units: 'kilometers' }
          );
          if (distance <= 0.03) {
            this.questionDetected({
              questionType: 'endPoi',
              ...this.gameService.endQuest,
              ...this.gameService.activeGame.tourId.endQuest
            }, null, activePerks);
            return false;
          }
    }

    _.forEach(_.filter(questions, (q)=>{
      return !q.isEndPOITimeline && !q.isEndPoi;
    }), (question) => {
        const distance = turf.distance(
        turf.point([position.lng, position.lat]),
        turf.point([question.lng, question.lat]),
        { units: 'kilometers' }
      );
      if (distance <= (this.gameService.activeGame.tourId.isDynamicQuestionCreation ? 0.03 : 0.015) && !question.isEndPOITimeline) {
        
        if(this.gameService.activeGame.tourId.isSequenceGame){
          if(_.filter(this.gameService.activeGame.quests, (q) => {
            return !q.isEndPOITimeline;
          })[0]._id === question._id){
            qDetect = true;
            this.questionDetected(question,null,activePerks);
            return false;
          }
        } else{
          qDetect = true;
          this.questionDetected(question,null,activePerks);
          return false;
        }
      }
    });


    /* if(!qDetect && !outsideCircle){
      this.dropNoty('info', this.localeService.getTrans("Well, there are no markers nearby."));
    } */

      if(this.gameService.activeGame.tourId.isBattleCircle && this.gameService.battleCircle.data.geometry && this.gameService.battleCircle.data.geometry.coordinates.length && position.lng && position.lat){
        if(!turf.booleanContains(turf.polygon(this.gameService.battleCircle.data.geometry.coordinates), turf.point([position.lng, position.lat]))){
          const pointsToLost = 1;
  
           new Noty({
              type: 'error',
              text: this.localeService.getTrans("You are standing outside the battle circle. You've lost ") + (pointsToLost) + this.localeService.getTrans(" points!"),
              killer: true
          }).show();
          outsideCircle = true;
          this.gameService.me.points -= pointsToLost ;
          this.updateUser();
        }
      }

    if(!qDetect){
      this.canLookup = true;
    }

    
  }

  // Vége

  updatePubnubUser(){

  }

  calculateRanking(){
    this.gameService.me.ranking = _.findIndex(_.orderBy(this.gameService.activeGame.players, ['points'], ['desc']), (r)=>{
      return (r.name === this.gameService.me.name);
    })
  }

  doEndGame(reason){
    this.http.post(environment.baseUrl + 'games/endGame', { gameId: this.gameService.pageConfig.g, playerId: this.gameService.me._id }).subscribe((data: any) => {
      this.storageService.removeStorage('fl_storage');
    });
   
    if(this.gameService.activeGame.tourId && this.gameService.activeGame.tourId.isEndScreen){
      return this.router.navigate(['/end-screen', { c: reason, g: this.gameService.pageConfig.g, t: this.gameService.activeGame.tourId._id, p: this.gameService.activeGame.partner._id , groupId: this.gameService.activeGame.groupId }]);
    }
    if (this.gameService.activeGame.isMuseum) {
        this.router.navigate(['/museum-leaderboard', { c: reason, g: this.gameService.pageConfig.g, t: this.gameService.activeGame.tourId._id, p: this.gameService.activeGame.partner._id , groupId: this.gameService.activeGame.groupId }]);
    } else {
      this.router.navigate(['/endGame', { c: reason, g: this.gameService.pageConfig.g, p: this.gameService.pageConfig.p, groupId: this.gameService.activeGame.groupId }]);
    }
  }

  endGame(reason, forcedClose?){
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }

    const endPOIQuestion = _.find(this.gameService.activeGame.quests, (qts)=>{return qts.isEndPOITimeline});

    if(endPOIQuestion && !forcedClose){
      this.doQuestion(endPOIQuestion);
    } else{
      if (this.gameService.activeGame.isEndPOI) {
        this.doEndGame(reason);
      } else {
        this.doEndGame(reason);
      }
    }
   
  }

  updateUserStress(){
    return this.http.post(environment.baseUrl + 'games/updatePlayerStress', {gameId: this.gameService.activeGame._id, player: this.gameService.me}).subscribe((data: any) => {});
  }

  getFeatureCenter() {
    let features = [];
    let featuresTurf;
    _.forEach(this.gameService.activeGame.quests, (q) => {
      features.push([q.lng, q.lat]);
    })
    featuresTurf = turf.points(features);
    let center = turf.center(featuresTurf);
    return center;
  }

  activateGameEndPOI() {

    /* if (this.gameService.activeGame.isEndPOI && !this.gameService.endQuest.isVisible) {
      let center = this.getFeatureCenter();
      this.gameService.endQuest.coordinates = center.geometry.coordinates;

      this.gameService.endQuest.isVisible = true;
      this.dropNoty('success', "<img src='./assets/images/pois/sh.png' width='30'>" + this.localeService.getTrans("Congrats! You've activated the safehouse! Now find it on the map and reach it before it's too late!"), 'safehouse')

    } */
  }

  getUserByTeamName(teamName){
    return this.http.post(environment.baseUrl + 'games/getPlayerByTeamName', {gameId: this.gameService.activeGame._id, player: teamName}).subscribe((data: any) => {
      this.gameService.me = data;
      this.gameService.activeGame.quests = [..._.filter(this.gameService.originalQuests, (q)=>{
        return this.gameService.me.passedQuests.indexOf(q._id) === -1 && !q.isEndPoi
      })]

      if(!_.find(this.gameService.activeGame.quests, (q)=>{
        return (!q.isEndPoi && q.isQuestion)
      })){
        if(this.gameService.activeGame.isEndPOI){
          this.gameService.activeGame.quests = [..._.filter(this.gameService.originalQuests, (q)=>{
            return this.gameService.me.passedQuests.indexOf(q._id) === -1
          })];
          if(_.find(this.gameService.activeGame.quests, (fe)=>{return fe.isEndPoi === true}) && !this.lastMarkerNotyDropped){
            this.dropNoty('success', this.localeService.getTrans('Congrats! You unlocked the last marker!'));
            this.gameService.endQuest = _.find(this.gameService.activeGame.quests, (fe)=>{return fe.isEndPoi === true});
            this.gameService.endQuest.isVisible = true;
            this.lastMarkerNotyDropped = true;
          }

          if(this.gameService.activeGame.quests.length === 0){
            this.endGame('end');
          }
          
         /*  if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
            this.endGame('end');
          } */
        } else{
          if(this.gameService.activeGame.quests.length === 0){
            this.endGame('end');
          }
         /*  if(_.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion}).length === 0){
            this.endGame('end');
          } */
        }
      }
      
    });
  }

  updateUserIfOK(){
    if(!this.stillUpdating){
      this.updateUserLocation();
    }
  }

  validateImage(points: number, photoURL: string){
    return this.http.post(environment.baseUrl + 'games/validateImageAutomatically', {userId: this.gameService.me._id, points, photoURL }).subscribe((data: any) => {
    });
  }

  updateUserLocation(){
    return this.http.post(environment.baseUrl + 'location/updatePlayerLocation', {gameId: this.gameService.activeGame._id, userId: this.gameService.me._id, lat: this.gameService.me.position.lat, lng: this.gameService.me.position.lng}).subscribe((data: any) => {
    });
  }

  updateUser(canLookup?, wasCorrect?){
   
    if(!this.modalActive || canLookup){
      this.stillUpdating = true;
      this.triggerRequest.next({ wasCorrect, canLookup });
    }
   
  }

  checkUserHasPaid(data){
    if(data && !data.hasPaid && data.questionStatuses.length >= 2 && this.gameService.activeGame.partner.isMuseum && !this.gameService.activeGame.partner.isDigitalMuseum){
      this.canLookup = false;
     
      Swal.fire({
        title: this.localeService.getTrans('Checkout'),
        text: this.localeService.getTrans("In order to continue the game we redirect you to the checkout page. After the checkout you will be redirected to the game."),
        showDenyButton: false,
        input: 'email',
        inputLabel: this.localeService.getTrans('Your email address'),
        inputPlaceholder: this.localeService.getTrans('Enter your email address'),
        imageUrl: '../../../assets/images/simplepay.png',
        background: '#fff url(../../../assets/images/bg.png)',
    imageWidth: 200,
        showCancelButton: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        showConfirmButton: true,
        confirmButtonColor: (this.gameService.activeGame && this.gameService.activeGame) ? this.gameService.activeGame.partner.color : '#000',
        confirmButtonText: this.localeService.getTrans(`Proceed to checkout`),
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.isPaymentActive = true;
          return this.http.post(environment.baseUrl + 'payments/checkoutSessionDirect', {checkoutObj: {
            tour: this.gameService.activeGame.tourId._id,
            game: this.gameService.activeGame._id,
            user: this.gameService.me._id,
            email: result.value,
            isIngamePayment: true
          }} ).subscribe((d: any) => {
            if(d){
              this.isPaymentActive = false;
              //window.location.href = d.paymentUrl;
              this.stripe.redirectToCheckout({ sessionId: d.data.id });
            } 
          });
        } else{
          this.isPaymentActive = false;
        }
      })
    }
  }

  dropSwal(title, content, type) {
    Swal.fire({
      title: title,
      text: content,
      icon: type,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#4E3B74',
      heightAuto: false,
    });
  }

  getCorrectAnswer(question) {
    if (question.type === '0' && question.answers.length > 0) {
      const correctAnswers = _.filter(question.answers, (o) => {
        return o.isTrue;
      });
      if (correctAnswers.length > 0) {
        return correctAnswers[0].answer;
      }
      return "";
    } else if ( (question.type === '1' || question.type === '2') && question.answers.length > 0) {
      return question.answers[0].answer;
    } else {
      return "";
    }
  }

  scrollTo(id){
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }


  dropNoty(type, text, clickEvent?){
    new Noty({
      type: type,
      text: text,
      killer: true,
      callbacks: {
        onClick: ()=>{
          if(clickEvent && clickEvent === 'scrollToBackpack'){
            this.scrollTo('backpacks');
          }
        }
      }
     
  }).show();
  }

  dropConfetti(){
    confetti();
  }

  

  doQuestion(question, isIndoorMode?, activePerks?){

    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    if(this.gameService.me.passedQuests.indexOf(question._id) === -1){
      this.gameService.me.passedQuests.push(_.clone(question._id));
      this.modalActive = true;

      if(activePerks && activePerks.bomb){
        this.gameService.geoServiceSubject$.next({bomb: true})
      }


    
      const instance = this.modalService.open(QuestionModalComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true
      });
      
      instance.componentInstance.question = _.cloneDeep(question);
      instance.result.then((result) => {
        instance.close();
        let wasCorrect = 0
        if(result.type !== 'closeWithoutEvent'){
          if (result.type === 'correct') {
            wasCorrect = 1;
            if(this.gameService.interimSettings.doubleActive){
              this.gameService.me.points += result.data.points*2;
            } else if(activePerks && activePerks.bomb){
              this.gameService.me.points += Math.round(result.data.points/2);
            } else{
              this.gameService.me.points += result.data.points;
            }
            Swal.fire({
              title: this.localeService.getTrans('Correct answer'),
              text: this.localeService.getTrans("You have earned ") + ((activePerks && activePerks.bomb) ? Math.round(result.data.points/2):  result.data.points) + this.localeService.getTrans(" points!"),
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {

              this.dropConfetti();
              if(question.isEndPOITimeline){
                this.endGame('endTimelinePOI', true);
              }

              if (result.isConfirmed) {
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], true, activePerks);
                }
              } 
            })
  
            this.gameService.interimSettings.doubleActive = false;
           
           
          } else if (result.type === 'incorrect') {
            wasCorrect = 2;
            Swal.fire({
              title: 'Ouuuch!',
              text: question.questionType !== 'ai' ? (this.localeService.getTrans('The correct answer would be: ') +  this.getCorrectAnswer(result.data) + '!') : null,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if(question.isEndPOITimeline){
                  this.endGame('endTimelinePOI', true);
                }
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], false, activePerks);
                }
              } 
            })
  
            this.gameService.interimSettings.doubleActive = false;
          } else if (result.type === 'outOfTime') {
            wasCorrect = 3;
            Swal.fire({
              title: this.localeService.getTrans('The time is up'),
              text: this.localeService.getTrans('The time is up') + ' ' + question.questionType !== 'ai' ? (this.localeService.getTrans('The correct answer would be: ') +  this.getCorrectAnswer(result.data)) : null,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4E3B74',
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if(question.isEndPOITimeline){
                  this.endGame('endTimelinePOI', true);
                }
                if(isIndoorMode){
                  this.questionDetected(this.gameService.activeGame.quests[0], true, activePerks);
                }
              } 
            })
            this.gameService.interimSettings.doubleActive = false;
          } else if(result.type === 'backpack'){
            this.dropNoty('info', this.localeService.getTrans("A new item has been added to your backpack!"), "scrollToBackpack")
  
            this.gameService.me.backpack.push(result.data.mediaUrl)
          } else if(result.type === 'endPoiSucess'){
            this.dropSwal(
              this.localeService.getTrans('Correct answer'),
              this.localeService.getTrans('Your rewards: 1000 points!'),
              'success'
            );
            wasCorrect = 1;
            this.gameService.me.points += 1000;
            this.gameService.me.isFinished = true;
          } else if(result.type === 'endPoiNotSucess'){
            wasCorrect = 2;
            this.dropSwal(
              this.localeService.getTrans('Incorrect answer'),
              this.localeService.getTrans('Try again'),
              'error'
            );
          } else if(result.type === 'closeWithInfo'){
            if(question.isEndPOITimeline){
              this.endGame('endTimelinePOI', true);
            }
          }
  
          if(this.gameService.activeGame.tourId.isQR){
            this.gameService.me = _.merge(this.gameService.me, {position: {
                lng: question.lng,
                lat: question.lat
              }
            }) 
          }
  
          if(this.gameService.activeGame.quests){
            _.remove(this.gameService.activeGame.quests, (x)=>{
                return x._id === question._id;
            })
          } 

       
         
          this.updateUser(true, wasCorrect);
          
        } else{
          this.canLookup = true;
        }

        this.modalActive = false
      });
    }

  }

  vibrate(){
    if(this.mapTouched){
      /* if('vibrate' in navigator){
        navigator.vibrate(500);
      } */
    }
  }

  questionDetected(question, isIndoorMode?, activePerks?) {

    if(activePerks){
      activePerks = _.cloneDeep(activePerks);
    }
    if(question.isEndPoi) {

      let noOfPassedQuestions = 0;
      let questions = _.filter(this.gameService.activeGame.quests, (quest)=>{return quest.isQuestion})
      _.forEach(this.gameService.me.passedQuests, (i)=>{
        if(questions.indexOf(i) !== -1){
          noOfPassedQuestions++;
        }
      })

      if(noOfPassedQuestions === questions.length-1){
        this.doQuestion(question, isIndoorMode, activePerks);
      } else{
        this.dropNoty('warning','This point will be available when you collected all the other questions on the map.');
      }

    } else{

      if (question.password) {
        this.passwordCheck(question, isIndoorMode, activePerks)
      } else { 
        this.doQuestion(question, isIndoorMode, activePerks);
      }
    }
  }

    
  }
